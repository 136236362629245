@use "sass:string";
@use "../../../node_modules/@noda-lib/assets/styles/index.scss" as assets-lib;

$root-poppins: "https://cdn.noda.live/noda-fonts/Poppins";
$root-roboto-mono: assets-lib.create-folder-path(assets-lib.$host, "RobotoMono");

$fonts-poppins: assets-lib.create-font-list("Poppins");
$fonts-roboto-mono: assets-lib.create-font-list("RobotoMono");

@include assets-lib.download-fonts("Roboto Mono", $fonts-roboto-mono, $root-roboto-mono);

//TODO: remove font download after redesign
@each $font, $fw in $fonts-poppins {
  $font-family: string.unquote($font);

  @font-face {
    font-family: "Poppins";
    src: url(#{$root-poppins}/#{$font-family}.ttf);
    font-weight: $fw;
  }
}
