* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: none;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Common */

aside,
nav,
footer,
header,
section,
main {
  display: block;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-size: inherit;
  font-weight: inherit;
}

ul,
ul li {
  list-style: none;
}

address {
  font-style: normal;
}

/* Form */

input::-ms-clear {
  display: none;
}

button,
input[type='submit'] {
  cursor: pointer;
  box-shadow: none;
}

button:disabled {
  cursor: default;
}

button {
  width: fit-content;
}

input:focus,
input:active,
button:focus,
button:active {
  outline: none;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

label {
  cursor: pointer;
}

legend {
  display: block;
}
