/* stylelint-disable selector-class-pattern */
@use 'sass:map' as map;
@use '../lib/lib.mixins' as lib-mixins;
@use './overrides/button.component' as button;
@use './overrides/link.component' as link;
@use './overrides/text-field.component' as text-field;
@use './overrides/date.component' as date;
@use './overrides/checkbox.component' as checkbox;
@use './overrides/toggle.component' as toggle;
@use './overrides/radio.component' as radio;
@use './overrides/hint.component' as hint;
@use './overrides/badge.component' as badge;
@use './overrides/global' as global;
@use './taiga.components.mixins' as taiga-components-mixins;

@include lib-mixins.apply-styles-to-library {
  // *Components
  @include button.override-styles;
  @include link.override-styles;
  @include badge.override-styles;

  // * Form Controls
  @include text-field.override-styles;
  @include checkbox.override-styles;
  @include radio.override-styles;
  @include toggle.override-styles;
}

@include taiga-components-mixins.dropdown-host-child {
  @include date.override-calendar-styles;
}

@include taiga-components-mixins.hints-host-child {
  @include hint.override-styles;
}

@include global.override-styles;
