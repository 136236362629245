@use 'variables' as *;
@use 'mixins' as *;
@import 'mat-styles/mat-input';
@import 'mat-styles/custom-swiper';
@import 'glossary-styles/glossary';
@import 'article-single-styles/article-single';
@import 'plugins-pages-styles/index';
@import 'cookies-page-styles/index';
@import 'hero-styles/hero';
@import 'src/assets/styles/events-styles/events';

body {
  overflow-x: hidden;
  background-color: rgb(var(--ivory));
  scroll-behavior: smooth;
  font-family: var(--assets-font-family-inter);
}

:root {
  --white: 255, 255, 255; //#FFFFFF
  --ivory: 255, 255, 254; //#FFFFFE
  --magnolia-white: 238, 239, 252; //#EEEFFC
  --whisper-blue: 232, 235, 244; //#E8EBF4
  --alice-blue: 230, 244, 254; //#E6F4FE
  --alice-blue-2: 239, 243, 245; //#EFF3F5
  --solitude: 230, 235, 241; //#E6EBF1
  --header-bg: 18, 10, 25; //#120A19
  --black-native: 0, 0, 0; //#000000
  --black-perl: 6, 8, 9; //#060809
  --black-1: 17, 17, 17; //#111111
  --neutral-1000: 23, 27, 44; //#171B2C
  --neutral-900: 41, 48, 77; //#29304D
  --neutral-700: 99, 107, 138; //#636B8A
  --neutral-600: 133, 140, 164; //#858CA4
  --neutral-500: 163, 167, 189; //#A3A7BD
  --neutral-300: 201, 204, 216; //#C9CCD8
  --neutral-200: 232, 234, 243; //#E8EAF3
  --neutral-100: 247, 249, 252; //#F7F9FC
  --lavender: 231, 234, 243; //#EAE7F3
  --lynch: 101, 108, 133; //#656C85
  --storm-grey: 105, 110, 130; //#696E82
  --grayish-blue: 72, 78, 104; //#484E68
  --twilight: 210, 197, 202; //#D2C5CA
  --lucky-point: 165, 170, 190; //#A5AABE
  --french-grey: 186, 184, 189; //#BAB8BD
  --violet-blue: 178, 84, 158; //#B2549E
  --fuchsia: 202, 83, 170; //#CA53AA
  --mulberry: 182, 86, 155; //#B6569B
  --deep-sky-blue: 0, 175, 240; //#00AFF0
  --gulf-blue: 59, 64, 89; //#3B4059
  --midnight: 39, 42, 54; //#272A36
  --nero: 33, 33, 33; //#212121
  --jaguar: 29, 28, 47; //#1D1C2F
  --dark-blue: 22, 26, 40; //#161A28
  --purple: 105, 54, 211; //#6936D3
  --purple-hover: 133, 103, 255; //#8567FF
  --purple-heart: 74, 22, 181; //#4A16B5
  --purple-heart-2: 121, 61, 199, 1; //#793DC7
  --purple-hot: 79, 42, 80; //#4F2A50
  --manatee: 133, 133, 151; //#858597
  --hollywood: 219, 0, 158; //#DB009E
  --silver-tree: 107, 231, 149; //#6BE795
  --light-slate-blue: 152, 103, 255; //#9867FF
  --burnt-orange: 255, 116, 60; //#FF743C
  --black: 9, 6, 6; //#090606
  --light-grey: 215, 215, 215; //#D7D7D7
  --blackcurrant: 37, 27, 46; //#251B2E
  --opium: 157, 141, 141; //#9D8D8D
  --eclipse: 60, 50, 50; //#3C3232
  --blackberry: 64, 14, 40; //#400E28
  --seal-brown: 33, 23, 23; //#211717
  --fruit-salad: 80, 169, 92; //#50A95C
  --supernova: 244, 182, 63; //#F4B63F
  --jagger: 59, 46, 71; //#3B2E47
  --salmon: 249, 119, 119; //#F97777
  --rock-blue: 144, 158, 178; //#909EB2
  --froly: 240, 110, 112; //#F06E70
  --froly-icon: 224, 104, 124; //#E0687C
  --pattens-blue: 203, 233, 254; //#CBE9FE
  --purple-icon: 108, 56, 210; //#6C38D2
  --midnight-express: 29, 34, 52; //#1D2234
  --midnight-express2: 36, 42, 64; //#242A40
  --deep-blush: 221, 102, 126; //#DD667E
  --oyster-pink: 211, 181, 181; //#D3B5B5
  --cornflower-blue: 123, 160, 255; //#7BA0FF
  --gainsboro: 227, 227, 227; //#E3E3E3
  --mulberry-2: 195, 92, 146; //#C35C92
  --haiti: 26, 22, 36; //#1A1624
  --raven: 101, 107, 118; //#656B76
  --light-slate-blue2: 148, 122, 255; //#947AFF
  --light-slate-blue3: 167, 140, 255; //#A78CFF
  --safety-orange: 255, 103, 0; //#FF6700
  --rouse: 241, 111, 111; // #F16F6F
  --dark-orchid: 132, 62, 205; //#843ECD
  --cerise: 211, 54, 111; //#D3366F
  --blue-gem: 68, 59, 175; //#443BAF
  --las-palmas: 191, 224, 59; //#BFE03B
  --curious-blue: 50, 138, 188; //#328ABC
  --deep-lilac: 145, 59, 175; //#913BAF
  --magenta: 219, 0, 158; //#DB009E

  //New colors
  --purple-01: 148, 121, 255; //#9479FF
  --purple-02: 133, 103, 255; //#8567FF;
  --purple-03: 74, 22, 181; //#4A16B5;
  --base-03: 240, 242, 248; //#F0F2F8;
  --base-04: 225, 228, 237; //#E1E4ED;
  --base-05: 208, 212, 227; //#D0D4E3;
  --base-07: 99, 107, 138; //#636B8A
  --base-08: 41, 48, 77; //#29304D
  --base-06: 177, 181, 203; //#B1B5CB
  --border-color: 225, 228, 237;
  --cobalt-blue: 29, 36, 69; //#1D2445
  --cosmic-blue: 22, 25, 42; //#16192A
  --positive-green: 58, 169, 129; //#3AA981
  --light-green: 54, 211, 117; //#36D375
  --pumpkin: 242, 99, 34; //#F26322
  --shamrock: 74, 201, 155; //#4AC99B
  --text-icons-link: 145, 118, 255; //#9176FF
  --additional-bg: 54, 130, 219; // #3682DB

  //New vars
  --text-01: rgba(var(--cobalt-blue), 0.96);
  --text-02: rgba(var(--cobalt-blue), 0.64);
  --text-03: rgba(var(--white), 0.6);
  --text-04: rgba(var(--white), 0.72);
  --text-icons-text-01: rgba(239, 241, 246, 0.96);
  --text-icons-text-02: rgba(239, 241, 246, 0.64);
  --text-icons-text-03: rgba(29, 36, 69, 0.4);
}

.link {
  color: var(--assets-primary);
  @include transition(color);

  @include hover {
    color: rgb(var(--purple-hover));
  }

  &:active {
    color: rgb(var(--purple-heart));
  }
}

.underline {
  text-decoration: underline;
}

// styles for new(redisigned) h1 ___________________

.h1-title {
  & * {
    font-family: var(--assets-font-family-archivo) !important;
  }

  @include font(40px, 44px);
  font-weight: 800;
  letter-spacing: -0.01em;
  color: rgb(var(--white));

  @include media('min', 'md') {
    @include font(52px, 58px);
    letter-spacing: -0.03em;
  }

  @include media('min', 'lg') {
    @include font(64px, 72px);
  }
}

// ____________________________________________________

.tag-sub-title,
.tag-sub-title noda-translate {
  font-family: var(--assets-font-family-archivo);
  font-weight: 500;
  color: var(--text-02);
  text-transform: uppercase;

  @include font(10px, 120%);
  letter-spacing: 2px;

  @include media('min', 'lg') {
    @include font(12px, 120%);
    letter-spacing: 3px;
  }
}

.tag-sub-title,
.tag-sub-title-m {
  padding: 4px 0;
  margin-bottom: 8px;
}

.tag-sub-title-m,
.tag-sub-title-m noda-translate {
  font-family: var(--assets-font-family-archivo);
  font-weight: 500;
  color: var(--text-03);
  text-transform: uppercase;
  @include font(12px, 120%);
  letter-spacing: 3px;
}

.heading-1 {
  @include font(32px, 115%);
  text-align: center;
  letter-spacing: -0.05em;
  font-weight: 800;
  @include media('min', 'md') {
    @include font(55px, 110%);
    letter-spacing: -0.03em;
  }
  @include media('min', 'lg') {
    @include font(68px, 108%);
  }
}

.heading-1-v2,
.heading-1-v2 noda-translate {
  font-family: var(--assets-font-family-archivo);
  text-align: center;
  font-weight: 800;
  color: var(--assets-text-01);
  @include font(32px, 100%);
  letter-spacing: -0.64px;

  @include media('min', 'md') {
    @include font(40px, 110%);
    letter-spacing: -0.8px;
  }
  @include media('min', 'lg') {
    @include font(56px, 110.714%);
    letter-spacing: -1.12px;
  }
}

.title-level-1 {
  color: rgb(var(--neutral-900));
  font-weight: 800;
  padding: 16px 0 0;
  @include font(40px, 110%);

  @include media('min', 'md') {
    @include font(52px, 112%);
  }

  @include media('min', 'lg') {
    padding: 24px 0;
    letter-spacing: -1.2px;
    @include font(52px, 112%);
  }

  @include media('min', 'xxl') {
    @include font(64px, 112%);
  }
}

.title-level-1-v2 {
  @include font(40px, 110%);
  letter-spacing: -0.025rem;
  color: rgb(var(--neutral-900));
  font-weight: 800;

  @include media('min', 'md') {
    @include font(52px, 111.538%);
    letter-spacing: -0.033rem;
  }

  @include media('min', 'lg') {
    @include font(64px, 112.5%);
    letter-spacing: -0.04rem;
  }
}

.text-level-1 {
  color: rgb(var(--neutral-1000));
  @include font(16px, 150%);
  font-weight: 400;
  white-space: pre-line;

  @include media('min', 'xxl') {
    letter-spacing: -0.5px;
  }
}

.heading-2 {
  font-weight: 800;
  @include font(32px, 115%);
  letter-spacing: -0.05em;

  &--default {
    margin: 24px 0;
    text-align: center;
    @include media('min', 'md') {
      @include font(55px, 110%);
      letter-spacing: -0.03em;
    }
  }
}

.heading-2-v2 {
  font-style: normal;
  font-weight: 800;
  @include font(32px, 100%);
  letter-spacing: -0.64px;

  @include media('max', 'md') {
    text-align: center;
  }

  @include media('min', 'md') {
    @include font(40px, 110%);
    letter-spacing: -0.8px;
  }

  @include media('min', 'lg') {
    @include font(56px, 110.714%);
    letter-spacing: -1.12px;
  }
}

.heading-3 {
  font-weight: 600;
  @include font(26px, 33px);
}

.heading-3-v2 {
  font-weight: 700;
  @include font(18px, 122.222%);

  @include media('min', 'md') {
    @include font(20px, 120%);
  }

  @include media('min', 'lg') {
    @include font(24px, 116.667%);
  }
}

.heading-4 {
  font-weight: 600;
  @include font(12px, 18px);
  letter-spacing: 1px;
  text-transform: uppercase;
  color: rgb(var(--violet-blue));
  text-align: center;
}

.heading-4-v2 {
  font-family: var(--assets-font-family-archivo);
  text-transform: uppercase;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 2px;
  @include font(10px, 120%);

  * {
    font-family: var(--assets-font-family-archivo);
  }

  @include media('min', 'lg') {
    @include font(12px, 120%);
    letter-spacing: 3px;
  }
}

.text-2 {
  @include font(16px, 28px);
}

.text-1,
.text-2-v2 {
  // P1, P2
  color: rgba(var(--white) 0.72);
  font-style: normal;
  font-weight: 400;

  @include media('max', 'md') {
    text-align: center;
    @include font(16px, 140%);
  }

  @include media('min', 'md') {
    @include font(16px, 140%);
  }

  @include media('min', 'lg') {
    @include font(18px, 140%);
  }
}

.text-1-v2 {
  @include font(16px, 22.4px);
  @include media('min', 'md') {
    @include font(18px, 25.2px);
  }
}

.text-3 {
  @include font(14px, 153%);
  letter-spacing: -0.02em;
  color: rgb(var(--midnight));
  @include media('min', 'md') {
    @include font(20px, 32px);
    letter-spacing: -1px;
  }
  @include media('min', 'lg') {
    @include font(16px, 28px);
    letter-spacing: normal;
  }
}

.text-3-v2 {
  color: rgb(var(--white));

  @include font(14px, 150%);

  &--hover {
    @extend .text-3-v2;
    cursor: pointer;

    &:hover {
      color: rgb(var(--purple-01));
      transition-property: color;
      transition-duration: var(--tui-duration, 300ms);
      transition-timing-function: ease-in-out;
    }
  }

  &--bold {
    // P2-bold
    @extend .text-3-v2;
    font-weight: 700;

    @include media('min', 'lg') {
      @include font(16px, 140%);
    }
  }

  &--fade-color {
    @extend .text-3-v2;
    color: var(--text-04);
  }
}

.text-2-v2--fade-color {
  @extend .text-2-v2;
  color: var(--text-04);
}

.gradient-bg {
  color: transparent !important;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-box-decoration-break: clone;
}

.link-hover {
  position: relative;
  width: max-content;
  @extend .gradient-hover;

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 1px;
    bottom: 0;
    left: 0;
    background: $gradient-default-dark;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }

  &:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }

  &:hover {
    transition: all 0.2s ease-out;
    background-image: $gradient-default-dark;
  }
}

.gradient-hover {
  &:hover {
    color: transparent !important;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-box-decoration-break: clone;
  }
}

.active-gradient {
  @extend .gradient-bg;
  background-image: $gradient-default;

  .nav__icon {
    svg-icon {
      display: none !important;
    }

    img {
      opacity: 1 !important;
    }
  }
}

.container--default {
  padding: 80px 16px;
  @include media('min', 'md') {
    padding: 140px 40px;
  }
  @include media('min', 'lg') {
    padding: 140px 48px;
  }
}

.remove-overflow {
  overflow-x: initial;
}

.hero-background {
  position: relative;
  overflow: hidden;
  z-index: 1;
  background-image: cdn-url('/noda-origin/banking/hero/hero-bg-new.svg');
  background-color: rgb(var(--neutral-900));
  background-repeat: no-repeat;
  background-size: cover;

  &--cells {
    background-image: cdn-url('/noda-origin/banking/hero/hero-bg.svg');

    @include media('min', 'md') {
      background-image: cdn-url('/noda-origin/banking/hero/hero-bg-md.svg');
    }
    @include media('min', 'lg') {
      background-image: cdn-url('/noda-origin/banking/hero/hero-bg-lg.svg');
    }
    @include media('min', 'xxl') {
      background-image: cdn-url('/noda-origin/banking/hero/hero-bg-xxl.svg');
    }
    @include media('min', 'full') {
      background-image: cdn-url('/noda-origin/banking/hero/hero-bg-full.svg');
    }
  }
}

.swipe-btn {
  @include flex(center, center);
  background: $gradient-default-2;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  position: absolute;
  top: calc(50% - 22px);
  cursor: pointer;
  z-index: -1;
  opacity: 0;
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);

  svg-icon {
    --icon-width: 8px;
    --icon-height: 12px;
  }

  &--right {
    right: -28px;
  }

  &--left {
    left: -28px;

    svg-icon {
      rotate: 180deg;
    }
  }
}

.swipe-btn-v2 {
  background: rgb(var(--purple));
  width: 48px;
  height: 48px;
  border-radius: 50%;
  position: absolute;
  top: calc(50% - 24px);
  cursor: pointer;
  z-index: -1;
  opacity: 0;
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.new-year-theme {
  &:not([new-year-theme='true']) {
    display: none;
  }
}

.section-wrapper {
  padding: 40px 16px;

  @include media('min', 'md') {
    padding: 60px 40px;
  }
  @include media('min', 'lg') {
    padding: 80px 50px;
  }
  @include media('min', 'xxl') {
    padding: 80px 150px;
  }
}

.section-heading {
  text-align: center;
  letter-spacing: -2.16px;
  font-weight: 800;
  @include font(54px, 100%);

  @include media('min', 'md') {
    letter-spacing: -3.2px;
    @include font(80px, 100%);
  }
  @include media('min', 'lg') {
    @include font(120px, 100%);
    letter-spacing: -4.8px;
  }
}

.section-1 {
  padding: 40px 16px 0;

  @include media('min', 'md') {
    padding: 60px 40px 0;
  }

  @include media('min', 'lg') {
    padding: 80px 50px 0;
  }

  @include media('min', 'xxl') {
    padding: 140px 0 0;
  }
}

.section-1-v2 {
  padding: 40px 16px 0;

  @include media('min', 'md') {
    padding: 60px 40px 0;
  }

  @include media('min', 'lg') {
    padding: 80px 48px 0;
  }

  @include media('min', 'xxl') {
    padding: 140px 0 0;
  }
}

.section-2 {
  position: relative;
  max-width: 1144px;
  margin: 0 auto;

  @extend .section-1;
}

.section-2-v2 {
  position: relative;
  max-width: 1140px;
  margin: 0 auto;

  @extend .section-1-v2;
}

.section-bottom-padding {
  padding-bottom: 40px;

  @include media('min', 'md') {
    padding-bottom: 60px;
  }
  @include media('min', 'lg') {
    padding-bottom: 80px;
  }
  @include media('min', 'xxl') {
    padding-bottom: 140px;
  }
}

.p2 {
  font-weight: 400;
  @include font(16px, 22.4px);

  @include media('min', 'lg') {
    @include font(18px, 25.2px);
  }
}

.p2-bold {
  @extend .p2;
  font-weight: 700;
  color: var(--assets-text-01);
}

.p3 {
  @include font(14px, 150%);
}

.p3-bold {
  font-weight: 600;
  @include font(12px, 130%);
  color: var(--text-01);

  @include media('min', 'lg') {
    font-weight: 700;
    @include font(14px, 150%);
  }
}

.p2-p3 {
  @extend .p3;

  @include media('min', 'lg') {
    @include font(16px, 22.4px);
  }
}

.p4 {
  @include font(12px, 16.8px);
}

.documents,
.doc-default {
  a {
    color: rgba(var(--purple));
    text-decoration: underline;
    @include transition();

    &:hover {
      color: rgb(var(--purple-hover));
    }

    &:active {
      color: rgb(var(--purple-heart));
    }
  }
}

.assets-content {
  .h1-doc,
  .h2,
  .h2-doc,
  .h3,
  .h4 {
    &,
    * {
      font-family: var(--assets-font-family-archivo);
    }
  }

  .h1-doc {
    @include font(32px, 36px);
    font-weight: 800;
    letter-spacing: -0.01em;

    @include media('min', 'md') {
      @include font(40px, 44px);
      letter-spacing: -0.02em;
    }

    @include media('min', 'lg') {
      @include font(48px, 54px);
      letter-spacing: -0.03em;
    }
  }

  .h2 {
    @include font(32px, 32px);
    font-weight: 800;
    letter-spacing: -0.02em;

    @include media('min', 'md') {
      @include font(40px, 44px);
    }

    @include media('min', 'lg') {
      @include font(56px, 62px);
    }
  }

  .h2-doc {
    @include font(20px, 24px);
    font-weight: 800;
    letter-spacing: -0.01em;

    @include media('min', 'md') {
      @include font(24px, 28px);
    }

    @include media('min', 'lg') {
      @include font(32px, 36px);
    }
  }

  .h3 {
    @include font(18px, 22px);
    @include media('min', 'md') {
      @include font(20px, 24px);
    }
    @include media('min', 'lg') {
      @include font(24px, 28px);
    }

    font-weight: 700;
  }

  .h4 {
    @include font(16px, 20px);
    font-weight: 700;

    @include media('min', 'md') {
      @include font(18px, 22px);
    }

    @include media('min', 'lg') {
      @include font(20px, 24px);
    }
  }

  .h5 {
    @include font(16px, 20px);
    font-weight: 700;

    @include media('min', 'md') {
      @include font(18px, 22px);
    }
  }
}

.assets-content [tuiButton] {
  &[data-appearance='transparent'] {
    // overrideTuiButtonStyles() takes next params
    // $background,
    // $backgroundHover,
    // $backgroundActive,
    // $backgroundDisabled,
    // $color,
    // $colorHover,
    // $colorActive,
    // $colorDisabled,
    // ––––––––––––––––––––––––––––––––––––––––––––––––
    @include overrideTuiButtonStyles(
      transparent,
      transparent,
      transparent,
      transparent,
      var(--assets-primary),
      var(--assets-accent),
      var(--assets-accent-active),
      var(--assets-primary)
    );
  }

  &[data-appearance='flat--dark-mode'] {
    @include overrideTuiButtonStyles(
      #fff,
      var(--assets-accent),
      var(--assets-accent-active),
      #fff,
      var(--assets-primary),
      #fff,
      #fff,
      var(--assets-primary)
    );
  }

  &[data-appearance='flat-bg'] {
    @include overrideTuiButtonStyles(
      var(--assets-base-01),
      var(--assets-accent),
      var(--assets-accent-active),
      var(--assets-base-01),
      var(--assets-primary),
      var(--assets-base-01),
      var(--assets-base-01),
      var(--assets-primary-hover)
    );
  }
}

.dark-mode {
  [tuiButton] [data-appearance='transparent'] {
    color: #fff;
  }
}

.card {
  [tuiButton] [data-appearance='outline'] {
    background: transparent;
  }
}

a.link {
  @extend .text-2-v2;
  color: rgb(var(--purple));
  text-decoration: underline;
}

p.paragraph:not(:last-of-type) {
  margin-bottom: 12px;
}

#cookiebanner .active .btnCookie {
  font-family: var(--assets-font-family-inter);
}

tui-input-phone-international {
  --t-group-margin: 0 !important;
  --tui-background-base: none;
  caret-color: var(--assets-info-fill);

  tui-textfield {
    background-color: var(--assets-textfield-background-color, var(--assets-base-01));
    color: var(--assets-textfield-input-color, var(--assets-text-01));

    &:hover {
      color: var(--assets-text-02);
    }

    &:after {
      margin-inline-start: 31px !important;
    }

    &[tuiIcons] {
      &:not(:has(input)) {
        padding-left: 8px;
      }

      &[tuiChevron] {
        &:after {
          block-size: 24px;
        }

        &:has(select[data-focus='true']):after {
          color: var(--assets-info-fill);
        }
      }
    }
  }

  .t-flag {
    inline-size: 20px !important;
    block-size: 20px !important;

    @include media('min', 'lg') {
      inline-size: 24px !important;
      block-size: 24px !important;
      min-width: 24px;
    }
  }

  .t-select {
    inline-size: 69px !important;

    @include media('min', 'lg') {
      inline-size: 76px !important;
    }
  }

  [tuiAppearance][data-appearance='textfield'] {
    box-shadow: none;
    outline: 1px solid rgb(208, 212, 227);
    font: var(--assets-font-text-l-medium);

    &::placeholder {
      color: var(--assets-textfield-label-color, --assets-text-03);
      font: var(--assets-font-text-l-medium);
      font-family: var(--assets-font-family-archivo);

      @include media('min', 'lg') {
        @include font(16px, 22.4);
      }
    }

    &:not(._empty) {
      font: var(--assets-font-text-l-medium);
      color: var(--assets-textfield-input-color, var(--assets-text-01));
    }
  }
}

tui-data-list [tuiOption] {
  width: 100%;
}

tui-dropdown-mobile {
  tui-textfield[data-size='m'] input {
    font: var(--assets-font-text-l-medium);
  }
}

tui-dropdown-mobile._sheet > .t-container {
  height: 100%;
}
