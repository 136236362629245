.filter {
  .swiper-slide {
    width: 40px!important;
    margin-right: 8px!important;

    &:last-child {
      margin-right: 0!important;
    }
  }
}
