@use 'sass:map' as map;

/* stylelint-disable selector-class-pattern */

@mixin set-fake-circle() {
  $circle-border-color: var(--assets-base-05);

  $toggle-sizes: (
    m: (
      circle: -4.5rem 0 0 0.75rem currentColor,
      border: -4.5rem 0 0 1rem $circle-border-color,
    ),
    s: (
      circle: -2.625rem 0 0 0.5rem currentColor,
      border: -2.625rem 0 0 0.65rem $circle-border-color,
    ),
  );

  @each $size, $elements in $toggle-sizes {
    &[data-size='#{$size}'] {
      &:after {
        box-shadow: map.get($elements, 'circle'), map.get($elements, 'border');
        right: -2px;
      }
    }
  }
}

@mixin override-styles {
  [tuiSwitch] {
    --tui-disabled-opacity: 1;

    transition: background-color var(--assets-animation);

    &[data-appearance='primary'] {
      background-color: var(--assets-primary) !important;

      &:hover {
        background-color: var(--assets-primary-hover) !important;
      }

      &:focus {
        background-color: var(--assets-primary-active) !important;
      }
    }

    &[data-appearance='secondary'] {
      background-color: var(--assets-base-02) !important;
      border: 1px solid var(--assets-base-05);

      &:hover {
        background-color: var(--assets-base-03) !important;
      }

      &:focus {
        background-color: var(--assets-base-04) !important;
      }

      @include set-fake-circle();
    }

    &:disabled {
      opacity: var(--tui-control-disabled-opacity) !important;
    }
  }
}
