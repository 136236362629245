/* stylelint-disable color-hex-length */
@use '../' as mixins;

$light-pallette: (
  // * BASE PALLETTE
  // * Base
  base: transparent,
  base-01: #fff,
  base-02: #f7f9fc,
  base-03: #f0f2f8,
  base-04: #e1e4ed,
  base-05: #d0d4e3,
  base-06: #b1b5cb,
  base-07: #636b8a,
  base-08: #29304d,
  base-09: #1d2445,
  base-stable-white: #fff,
  // * Primary
  primary: #6936d3,
  primary-hover: #8567ff,
  primary-active: #4a16b5,
  // * Secondary
  secondary: #dfe2f0,
  secondary-hover: #cfd0e3,
  secondary-active: #c5c7db,
  // * Accent
  accent: #ff6700,
  accent-hover: #ff8d69,
  accent-active: #ed5a2b,
  // * Clear
  clear: #1d24450a,
  clear-hover: #1d244514,
  clear-active: #1d24451f,
  // *TEXT PALLETTE
  // * Text
  text-01: #1d2445f5,
  text-02: #1d2445a3,
  text-03: #1d244566,
  // * Link
  link: #6936d3,
  link-hover: #8567ff,
  // * Positive
  positive: #3aa981,
  positive-hover: #7ac5aa,
  // * Negative
  negative: #ff5e68,
  negative-hover: #ff8e8e,
  negative-active: #f45165,
  // *STATUS PALLETTE
  // * Success
  success-fill: #4ac99b,
  success-bg-hover: #4ac99b29,
  success-bg: #4ac99b14,
  // * Error
  error-fill: #ff5e5e,
  error-bg-hover: #ff5e5e3d,
  error-bg: #ff5e5e1f,
  // * Warning
  warning-fill: #ffc700,
  warning-bg-hover: #ffc7003d,
  warning-bg: #ffc7001f,
  // * Info
  info-fill: #6936d3,
  info-bg-hover: #8567ff3d,
  info-bg: #8567ff1f,
  // * Neutral
  neutral-fill: #a4aac1,
  neutral-bg-hover: #a4aac152,
  neutral-bg: #a4aac133,
  // * SUPPORT PALLETTE
  support-01: #a8cef1,
  support-02: #3682db,
  support-03: #8dda71,
  support-04: #34b41f,
  support-05: #e29398,
  support-06: #b8474e,
  support-07: #fcc068,
  support-08: #ff8a00,
  support-09: #dab3f9,
  support-10: #7b439e,
  support-11: #fee797,
  support-12: #fcbb14,
  support-13: #ea97c4,
  support-14: #bd65a4,
  support-15: #7fd7cc,
  support-16: #2fad96,
  support-17: #d4aca2,
  support-18: #9d6f64,
  support-19: #d2e9a2,
  support-20: #aadc42,
  support-21: #a0c5df,
  support-22: #3682db1f
);
$dark-pallette: (
  // * BASE PALLETTE
  // * Base
  base-01: #10121a,
  base-02: #12141a,
  base-03: #171b24,
  base-04: #25282d,
  base-05: #343a45,
  base-06: #4d586e,
  base-07: #89929f,
  base-08: #ccd3de,
  base-09: #fdfdfd,
  base-stable-white: #fff,
  // * Primary
  primary: #6347d5,
  primary-hover: #9176ff,
  primary-active: #523cab,
  // * Secondary
  secondary: #44434b,
  secondary-hover: #65626f,
  secondary-active: #908c9f,
  // * Accent
  accent: #ff6700,
  accent-hover: #ff8d69,
  accent-active: #ff8d69,
  // * Clear
  clear: #3b3f48,
  clear-hover: #4e525a,
  clear-active: #74767d,
  // *TEXT PALLETTE
  // * Text
  text-01: #fff,
  text-02: #d6dff5cc,
  text-03: #eff1f666,
  // * Link
  link: #9e91e2,
  link-hover: #cec5ff,
  // * Positive
  positive: #44c596,
  positive-hover: #3aa981,
  // * Negative
  negative: #f16476,
  negative-hover: #ff8e8e,
  negative-active: #f45165,
  // *STATUS PALLETTE
  // * Success
  success-fill: #41b98e,
  success-bg-hover: #0a5b4d,
  success-bg: #143c39,
  // * Error
  error-fill: #ff5e5e,
  error-bg-hover: #af3b44,
  error-bg: #7d313a,
  // * Warning
  warning-fill: #ffc700,
  warning-bg-hover: #a88000,
  warning-bg: #785f00,
  // * Info
  info-fill: #6347d5,
  info-bg-hover: #483493,
  info-bg: #372d6f,
  // * Neutral
  neutral-fill: #a4aac1,
  neutral-bg-hover: #6b7184,
  neutral-bg: #4e5464,
  // * SUPPORT PALLETTE
  support-01: #a8cef1,
  support-02: #3682db,
  support-03: #8dda71,
  support-04: #34b41f,
  support-05: #e29398,
  support-06: #b8474e,
  support-07: #fcc068,
  support-08: #ff8a00,
  support-09: #dab3f9,
  support-10: #7b439e,
  support-11: #fee797,
  support-12: #fcbb14,
  support-13: #ea97c4,
  support-14: #bd65a4,
  support-15: #7fd7cc,
  support-16: #2fad96,
  support-17: #d4aca2,
  support-18: #9d6f64,
  support-19: #d2e9a2,
  support-20: #aadc42,
  support-21: #a0c5df,
  support-22: #3682db66
);

@include mixins.apply-theme($light-pallette, $dark-pallette);
