@use './assets.mixins' as public-mixins;

.assets-absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.assets-overlay-container {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
}

.assets-bg-transparent {
  background-color: var(--assets-base);
}

.assets-for-mobile {
  @include public-mixins.desktop {
    display: none;
  }
}

.assets-for-desktop {
  @include public-mixins.mobile {
    display: none;
  }
}

.assets-none-events {
  pointer-events: none;
}

.assets-block {
  display: block;
}

.assets-full-width {
  width: 100%;
}

.assets-no-events {
  cursor: none;
}

.assets-hidden {
  visibility: hidden;
}

.assets-display-none {
  display: none;
}

.assets-label-container {
  display: flex;
  gap: var(--assets-label-gap, var(--assets-label-default-gap));

  &[data-assets-label-position='left'],
  &[data-assets-label-position='right'] {
    align-items: center;
  }

  &[data-assets-label-position='right'] {
    flex-direction: row;
  }

  &[data-assets-label-position='left'] {
    .assets-text-label {
      order: -1;
    }
  }

  &[data-assets-label-position='bottom'],
  &[data-assets-label-position='top'] {
    flex-direction: column;
  }

  &[data-assets-label-position='top'] {
    .assets-text-label {
      order: -1;
    }
  }
}

.assets-space {
  &-left {
    &::before {
      content: '\0000a0';
      display: inline-block;
    }
  }

  &-right {
    &::after {
      content: '\0000a0';
      display: inline-block;
    }
  }

  &-inline {
    &::before,
    &::after {
      content: '\0000a0';
      display: inline-block;
    }
  }
}
