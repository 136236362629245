.page-cookies {
  a {
    color: inherit;
    text-decoration: underline;
    cursor: pointer;

    &:hover {
      color: rgb(var(--purple));
    }
  }
}
