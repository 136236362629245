/* stylelint-disable selector-class-pattern */
@mixin override-calendar-styles {
  // * Calendar
  tui-calendar {
    .t-content.t-calendar-title {
      font-weight: 400 !important;

      button[tuiLink] {
        color: var(--assets-primary);
      }
    }

    .t-cell {
      border-right: none !important;
      border-left: none !important;

      // все числа вне диапазона:
      &:not(&[data-range]) > .t-item {
        &:hover {
          background-color: var(--assets-base-03) !important;
        }

        &:active {
          background-color: var(--assets-secondary-active) !important;
        }
      }

      // число начала и число конца диапазона:
      &[data-range='single'] > .t-item,
      &[data-range='start'] > .t-item,
      &[data-range='end'] > .t-item {
        &::before,
        &::after {
          background-color: var(--assets-primary) !important;
        }

        &:hover {
          &::before,
          &::after {
            background-color: var(--assets-primary-hover) !important;
          }
        }

        &:active {
          user-select: none;

          &::before,
          &::after {
            background-color: var(--assets-primary-active) !important;
          }
        }
      }

      // фикс стремной серой палки на фоне:
      &[data-range='start'],
      &[data-range='end'] {
        &::before {
          background-color: var(--assets-base-03) !important;
        }
      }

      // все числа, выделенные между start и end:
      &_interval:not(&[data-range]) > .t-item {
        background-color: var(--assets-base-03) !important;
      }
    }
  }

  // * Dropdown
  tui-dropdown:has(tui-calendar) {
    border-radius: var(--assets-radius-l) !important;
    box-shadow:
      0 25px 15px 0 rgb(42 0 129 / 3%),
      0 11px 11px 0 rgb(42 0 129 / 4%),
      0 3px 6px 0 rgb(42 0 129 / 5%) !important;
  }
}
