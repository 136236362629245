/* stylelint-disable selector-class-pattern */
@use 'sass:map' as map;
@use '@taiga-ui/core/styles/taiga-ui-local.scss' as taiga-ui;

$button-sizes: (
  l: (
    padding-inline: var(--assets-btn-padding-inline-l, 32px),
    font: var(--assets-font-text-xl-bold),
    margin: 6px,
    height: 56px,
    icon-component-size: var(--assets-btn-icon-component-size-l, 24px),
    // icon-component-size belongs to <assets-icon /> component
    icon-size: var(--assets-btn-icon-size-l, 18px),
    // icon-size belong to <tui-svg /> component
    radius: var(--assets-btn-radius-l, var(--assets-radius-l)),
  ),
  m: (
    padding-inline: var(--assets-btn-padding-inline-m, 16px),
    font: var(--assets-font-text-l-bold),
    height: 48px,
    margin: 5px,
    icon-component-size: var(--assets-btn-icon-component-size-m, 24px),
    icon-size: var(--assets-btn-icon-size-m, 16px),
    radius: var(--assets-btn-radius-m, var(--assets-radius-m)),
  ),
  s: (
    padding-inline: var(--assets-btn-padding-inline-s, 12px),
    font: var(--assets-font-text-m-bold),
    height: 38px,
    margin: 4px,
    icon-component-size: var(--assets-btn-icon-component-size-s, 20px),
    icon-size: var(--assets-btn-icon-size-s, 14px),
    radius: var(--assets-btn-radius-s, var(--assets-radius-s)),
  ),
  xs: (
    padding-inline: var(--assets-btn-padding-inline-xs, 8px),
    font: var(--assets-font-text-s-bold),
    height: 24px,
    margin: 2px,
    icon-component-size: var(--assets-btn-icon-component-size-xs, 16px),
    icon-size: var(--assets-btn-icon-size-xs, 12px),
    radius: var(--assets-btn-radius-xs, var(--assets-radius-xs)),
  ),
);

$button-icon-sizes: (
  l: (
    padding-inline: var(--assets-btn-padding-inline-l, 16px),
    font: var(--assets-font-text-xl-bold),
    height: 56px,
    icon-component-size: var(--assets-btn-icon-component-size-l, 24px),
    icon-size: var(--assets-btn-icon-size-l, 24px),
    radius: var(--assets-btn-radius-l, var(--assets-radius-m)),
  ),
  m: (
    padding-inline: var(--assets-btn-padding-inline-m, 12px),
    font: var(--assets-font-text-l-bold),
    height: 48px,
    icon-component-size: var(--assets-btn-icon-component-size-m, 24px),
    icon-size: var(--assets-btn-icon-size-m, 24px),
    radius: var(--assets-btn-radius-m, var(--assets-radius-m)),
  ),
  s: (
    padding-inline: var(--assets-btn-padding-inline-s, 9px),
    font: var(--assets-font-text-m-bold),
    height: 38px,
    icon-component-size: var(--assets-btn-icon-component-size-s, 20px),
    icon-size: var(--assets-btn-icon-size-s, 20px),
    radius: var(--assets-btn-radius-s, var(--assets-radius-s)),
  ),
  xs: (
    padding-inline: var(--assets-btn-padding-inline-xs, 4px),
    font: var(--assets-font-text-s-bold),
    height: 24px,
    icon-component-size: var(--assets-btn-icon-component-size-xs, 16px),
    icon-size: var(--assets-btn-icon-size-xs, 16px),
    radius: var(--assets-btn-radius-xs, var(--assets-radius-s)),
  ),
);

@mixin apply-button-sizes($sizes) {
  @each $size, $values in $sizes {
    &[data-size='#{$size}'] {
      --tui-height-#{$size}: map.get($values, 'height');
      --assets-icon-component-size: #{map.get($values, 'icon-component-size')};
      --t-margin: 0;

      height: map.get($values, 'height');
      padding-inline: map.get($values, 'padding-inline') !important;
      font: map.get($values, 'font');
      border-radius: map.get($values, 'radius');

      &:after {
        margin-left: #{map.get($values, 'margin')};
        font-size: #{map.get($values, 'icon-size')};
      }
    }
  }
}

@mixin override-styles {
  --assets-button-color: var(--assets-base-stable-white);
  --assets-button-bg: var(--assets-primary);
  --assets-button-hover-color: var(--assets-base-01);
  --assets-button-hover-bg: var(--assets-link-hover);
  --assets-button-active-bg: var(--assets-primary-active);
  --assets-button-active-color: var(--assets-base-01);
  --assets-button-disabled-bg: var(--assets-link-hover);
  --assets-button-disabled-color: var(--assets-base-stable-white);
  --assets-button-border-color: var(--assets-base);
  --assets-button-state-border-color: var(--assets-base);

  [tuiButton] {
    @include apply-button-sizes($button-sizes);
  }

  [tuiIconButton] {
    @include apply-button-sizes($button-icon-sizes);
  }

  [tuiButton],
  [tuiIconButton] {
    --tui-disabled-opacity: 0.48;

    @include taiga-ui.wrapper-hover {
      background-color: var(
        --assets-button-hover-bg
      ) !important; // 1688 tui добавили свой background на состояния, поэтому нужно перебить через !important
      color: var(--assets-button-hover-color);
      border-color: var(--assets-button-state-border-color);
    }

    @include taiga-ui.wrapper-active {
      background-color: var(--assets-button-active-bg) !important;
      color: var(--assets-button-active-color);
      border-color: var(--assets-button-state-border-color);
    }

    @include taiga-ui.wrapper-disabled {
      background-color: var(--assets-button-disabled-bg) !important;
      color: var(--assets-button-disabled-color);
      border-color: var(--assets-button-state-border-color);
    }

    font: var(--assets-font-text-l-bold);
    color: var(--assets-button-color);
    background-color: var(--assets-button-bg);
    border: 1px solid var(--assets-button-border-color);
    box-shadow: none;

    &[data-appearance='primary'] {
      @include primary-button;
    }

    &[data-appearance='outline'] {
      @include outline-button;
    }

    &[data-appearance='flat'] {
      @include flat-button;
    }

    &[data-appearance='flat-tertiary'] {
      @include flat-tertiary-button;
    }

    &[data-appearance='gray'] {
      @include gray-button;
    }

    &[data-appearance='gray-outline'] {
      @include gray-outline-button;
    }

    &[data-appearance='gray-flat'] {
      @include gray-flat-button;
    }

    &[data-appearance='accent'] {
      @include accent-button;
    }

    &[data-appearance='accent-outline'] {
      @include accent-outline-button;
    }
  }
}

@mixin primary-button {
  --assets-button-bg: var(--assets-primary);
  --assets-button-hover-color: var(--assets-base-stable-white);
  --assets-button-hover-bg: var(--assets-link-hover);
  --assets-button-active-bg: var(--assets-primary-active);
  --assets-button-disabled-bg: var(--assets-link-hover);
}

@mixin outline-button {
  --assets-button-color: var(--assets-link);
  --assets-button-bg: var(--assets-base);
  --assets-button-hover-bg: var(--assets-secondary);
  --assets-button-hover-color: var(--assets-link);
  --assets-button-active-bg: var(--assets-secondary-hover);
  --assets-button-active-color: var(--assets-link);
  --assets-button-disabled-bg: var(--assets-base);
  --assets-button-disabled-color: var(--assets-link);
  --assets-button-border-color: var(--assets-link);
  --assets-button-state-border-color: var(--assets-secondary-active);
}

@mixin flat-button {
  --assets-button-color: var(--assets-link);
  --assets-button-bg: var(--assets-base);
  --assets-button-hover-bg: var(--assets-secondary);
  --assets-button-hover-color: var(--assets-link);
  --assets-button-active-bg: var(--assets-secondary-hover);
  --assets-button-active-color: var(--assets-link);
  --assets-button-disabled-bg: var(--assets-base);
  --assets-button-disabled-color: var(--assets-link);
}

@mixin flat-tertiary-button {
  --assets-button-color: var(--assets-link);
  --assets-button-bg: var(--assets-base);
  --assets-button-hover-color: var(--assets-link-hover);
  --assets-button-hover-bg: var(--assets-base);
  --assets-button-active-bg: var(--assets-base);
  --assets-button-active-color: var(--assets-link-active);
  --assets-button-disabled-bg: var(--assets-base);
  --assets-button-disabled-color: var(--assets-link-hover);
}

@mixin gray-button {
  --assets-button-color: var(--assets-text-01);
  --assets-button-bg: var(--assets-base-04);
  --assets-button-hover-bg: var(--assets-clear-hover);
  --assets-button-hover-color: var(--assets-text-01);
  --assets-button-active-bg: var(--assets-clear-active);
  --assets-button-active-color: var(--assets-text-01);
  --assets-button-disabled-bg: var(--assets-base-04);
  --assets-button-disabled-color: var(--assets-text-01);
}

@mixin gray-outline-button {
  --assets-button-color: var(--assets-text-01);
  --assets-button-bg: var(--assets-base);
  --assets-button-hover-bg: var(--assets-clear-hover);
  --assets-button-hover-color: var(--assets-text-01);
  --assets-button-active-bg: var(--assets-clear-active);
  --assets-button-active-color: var(--assets-text-01);
  --assets-button-disabled-bg: var(--assets-base);
  --assets-button-disabled-color: var(--assets-text-01);
  --assets-button-border-color: var(--assets-base-05);
  --assets-button-state-border-color: var(--assets-base-04);
}

@mixin gray-flat-button {
  --assets-button-color: var(--assets-text-01);
  --assets-button-bg: var(--assets-base);
  --assets-button-hover-bg: var(--assets-base-04);
  --assets-button-hover-color: var(--assets-text-01);
  --assets-button-active-bg: var(--assets-base-05);
  --assets-button-active-color: var(--assets-text-01);
  --assets-button-disabled-bg: var(--assets-base);
  --assets-button-disabled-color: var(--assets-text-01);
}

@mixin accent-button {
  --assets-button-bg: var(--assets-negative);
  --assets-button-hover-color: var(--assets-base-stable-white);
  --assets-button-hover-bg: var(--assets-negative-hover);
  --assets-button-active-bg: var(--assets-negative-active);
  --assets-button-disabled-bg: var(--assets-negative-hover);
}

@mixin accent-outline-button {
  --assets-button-bg: var(--assets-base);
  --assets-button-color: var(--assets-negative);
  --assets-button-hover-color: var(--assets-base-stable-white);
  --assets-button-hover-bg: var(--assets-negative-hover);
  --assets-button-active-bg: var(--assets-negative-active);
  --assets-button-disabled-bg: var(--assets-base);
  --assets-button-disabled-color: var(--assets-negative);
  --assets-button-border-color: var(--assets-negative);
  --assets-button-state-border-color: var(--assets-negative-hover);
}
