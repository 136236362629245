@use '../lib/lib.mixins' as lib-mixins;
@use '../lib/lib.constants' as lib-const;
@use '../taiga/taiga.constants' as taiga-const;

$app: (
  // *Shared
  animation: 0.2s ease-in-out,
  max-z-index: 1000,
  body-bg: var(--assets-base),
  // * Icons
  icon-default-button-offset: 5px,
  // *Label
  label-default-gap: 12px // *Buttons
);
$taiga: (
  autofill: var(--assets-base),
  disabled-opacity: 1,
  control-disabled-opacity: 0.56,
  font-text: var(--assets-font-family),
  text-font: var(--assets-font-family),
  heading-font: var(--assets-font-family),
  font-heading: var(--assets-font-family),
);
$both: (
  radius-xs: 2px,
  radius-s: 4px,
  radius-m: 6px,
  radius-l: 6px,
  radius-xl: 12px,
  radius-xxl: 16px,
);

@mixin apply-app-variables($variables, $prefix: lib-const.$global-prefix) {
  :root {
    @include lib-mixins.apply-variables($variables, $prefix);
  }
}

/*
@param $type имеет 3 значения:
 ? 1. 'app' - из $variables будут добавлены переменные только с префиксом assets-{{$variables[key]}}
 ? 2. 'taiga' - из $variables будут добавлены переменные только с префиксом tui-{{$variables[key]}} (те переменные, которые нужны для taiga-ui)
? 3. 'both' - из $variables будут добавлены переменные с префиксом tui-{{$variables[key]}} и assets-{{$variables[key]}}  (выполнятся пункты 1 и 2 )
*/
@mixin override-app-variables($variables, $type) {
  @if $type == 'taiga' {
    @include apply-app-variables($variables, taiga-const.$taiga-prefix);
  } @else if $type == 'app' {
    @include apply-app-variables($variables, lib-const.$global-prefix);
  } @else if $type == 'both' {
    @include apply-app-variables($variables, taiga-const.$taiga-prefix);
    @include apply-app-variables($variables, lib-const.$global-prefix);
  }
}

@include override-app-variables($app, 'app');
@include override-app-variables($taiga, 'taiga');
@include override-app-variables($both, 'both');
