
noda-plugins,
noda-plugins-single-page {
  .pre-footer {
    @include media('min', 'md') {
      background-image: cdn-url("/noda-origin/pre-footer/bg-new.svg");
    }

    & .container {
      @include media('min', 'md') {
        min-height: 346px;
      }

      @include media('min', 'lg') {
        min-height: 514px;
      }
    }

    & .pre-footer__info {
      row-gap: 24px;
    }

    & .pre-footer__title {
      margin: 0;
    }

    & .pre-footer__btn {
      @include media('min', 'md') {
        margin-top: 0;
      }
    }
  }
}

.plugin-single-page {
  .heading-1 {
    text-align: left;

    @include font(36px, 112%);
    letter-spacing: -0.02em;
    gap: 18px;

    @media screen and (max-width: 418px) {
      word-break: break-word;
    }

    @include media('min', 'md') {
      @include font(48px, 104%);
      gap: 32px;
    }
    @include media('min', 'lg') {
      @include font(56px, 104%);
    }
  }

  p:not(.text-2-v2) {
    line-height: 150%;
    @include media('max', 'md') {
      font-size: 14px;
      letter-spacing: -0.28px;
    }
    @include media('min', 'md') {
      font-size: 16px;
    }
  }

  // SIDEBAR
  .sidebar {
    list-style: none;

    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 10px;

    @include media('min', 'lg') {
      flex-direction: column;
    }
  }

  .sidebar__item {
    position: relative;
    max-width: fit-content;
    border-radius: 52px;
    border: 1px solid rgba(167, 140, 255, 0.18);
    background: rgb(var(--neutral-100));

    &:hover,
    &.active {
      &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        margin: -2px;
        border-radius: inherit;
        background: $gradient-default;
      }
    }
  }

  .sidebar__link {
    display: inline-flex;
    align-items: center;
    gap: 8px;
    padding: 4px;
    padding-right: 12px;
    color: initial;

    font-size: 14px;
    line-height: 150%;
    letter-spacing: -0.28px;

    img {
      width: 24px;
      height: 24px;
    }
  }
  // SIDEBAR END

  // SWIPERS
  .swiper-sidebar {
    padding: 1px;
    max-width: calc(100vw - 32px);
    margin-left: 0;

    .swiper-slide {
      max-width: fit-content;
    }
  }

  .plugins-swiper {
    margin-top: 24px;

    .swiper-slide {
      max-width: 288px;
    }

    &__slide a {
      display: flex;
      flex-direction: column;
    }
  }
  // SWIPERS END

  .article-card {
    &__link {
      display: flex;
      flex-direction: column;
      --neutral-grey-for-cart: 246, 246, 248;

      color: rgb(var(--neutral-900));
      padding: 32px 24px 24px 24px;
      width: 100%;
      height: 100%;

      border-radius: 8px;
      background: rgb(var(--neutral-grey-for-cart));
      backdrop-filter: blur(10px);
      transition: all 0.375s ease-in-out;

      &:hover {
        background: rgb(var(--neutral-100));
        box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.08);
        backdrop-filter: blur(10px);
      }

      &:hover .round-gradient {
        background: none;

        span {
          color: rgb(var(--white));
        }

        svg-icon {
          --webkit-animation: slide-right-sm 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
          animation: slide-right-sm 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
          --icon-color: rgb(var(--white));
        }

        .round-gradient__wrapper {
          background: $gradient-default;
        }
      }
    }

    &__title {
      margin-top: 16px;
    }

    &__text {
      font-size: 14px;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      overflow: hidden;
      margin-top: 4px;
      margin-bottom: auto;
    }

    &__btn-learn-more {
      display: block;
      margin-top: min(16px);
    }
  }
}
