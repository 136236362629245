$max-z-index: 1000;

// * API
$store-host: 'https://nodastore.azureedge.net';
$store-new: 'https://nodastore.azureedge.net/noda-origin/header/developers-checkout.svg';

// * Fonts
$inter-font: 'Inter', sans-serif;
$poppins-font: 'Poppins', sans-serif;
$roboto-mono-font: 'Roboto Mono', sans-serif;

// * Shadows
$input-focus: 0px 0px 0px 3px rgba(105, 54, 211, 0.12);
$balance-shadow: 42.4529px 48.3766px 80.9567px rgba(6, 8, 9, 0.2);
$swiper-shadow: 0px 2.75522px 20.6642px rgba(7, 16, 35, 0.12);
$img-shadow: 0px 20.144px 59.7821px rgba(220, 102, 128, 0.32);
$dashboard-shadow: 0px 0px 8.40272px rgba(41, 48, 77, 0.04);
$img-hub-shadow: 0px 20.138px 38.9768px rgba(220, 102, 128, 0.12);
$img-swiper-shadow: 0px 16px 32px rgba(242, 111, 111, 0.12);
$currency-shadow: 24.1829px 27.5573px 46.1162px rgba(6, 8, 9, 0.2);
$code-shadow: 0px 0px 143.426px rgba(125, 75, 113, 0.2);
$cookie-popup-shadow: 0px -4px 12px 0px rgba(0, 0, 0, 0.2);
$card-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.08);

// * Gradients
$gradient-default: linear-gradient(89.94deg, #6936d3 0.05%, #f16f6f 99.95%);
$gradient-default-2: linear-gradient(180.02deg, #6936d3 0.01%, #f16f6f 99.99%);
$gradient-default-3: linear-gradient(128.56deg, #6936d3 1.27%, #f16f6f 145.48%);
$gradient-default-4: linear-gradient(129.81deg, #6d38d1 5.03%, #ee6e71 72.73%);
$gradient-default-hover: linear-gradient(89.94deg, #f16f6f 0.05%, #6936d3 99.95%);
$gradient-default-dark: linear-gradient(90deg, #aa82ff 3.2%, #eb6c73 116%);
$gradient-default-light: linear-gradient(90deg, #6936d3 0.05%, #f16f6f 99.95%);

$gradient-gray: linear-gradient(281.85deg, #e3e0ea 21.87%, #ffffff 58.31%);
$gradient-bg: radial-gradient(
  50% 50% at 50% 50%,
  rgba(0, 0, 0, 0.4) 0%,
  rgba(0, 0, 0, 0.156) 30.73%,
  rgba(216, 27, 95, 0.196) 70%
);
$gradient-semi: linear-gradient(180deg, #29304d 0%, rgba(36, 42, 64, 0) 100%);
$gradient-red: linear-gradient(180deg, #370d24 0%, rgba(55, 13, 36, 0) 100%);
$gradient-pink: linear-gradient(307.02deg, #dc8fab 6.47%, #ffffff 67.56%);
$gradient-blue: linear-gradient(101deg, #af87ff 8.3%, #6da7ec 58.43%);
$gradient-light-blue: linear-gradient(270deg, #a6c5f4 7.16%, #cdd5ff 16.16%, #eff6ff 87.97%);
$gradient-silver: linear-gradient(281.85deg, #b0aeb4 21.87%, #ffffff 58.31%);
$gradient-silver-2: linear-gradient(346deg, #b0aeb4 0%, #fff 72.39%);

// * Border-radius
$border-61: 61px;
$border-52: 52px;
$border-44: 44px;
$border-40: 40px;
$border-38: 38px;
$border-34: 34px;
$border-32: 32px;
$border-24: 24px;
$border-20: 20px;
$border-14: 14px;
$border-16: 16px;
$border-8: 8px;
$border-6: 6px;
$border-4: 4px;
$border-5: 5px;
$border-2: 2px;
