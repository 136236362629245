@use 'sass:map' as map;

$status-colors-map: (
  default: --assets-info-fill,
  disabled: --assets-base-04,
  error: --assets-error-fill,
  warning: --assets-accent,
  success: --assets-success-fill,
);

[data-control-status] {
  @each $status, $color in $status-colors-map {
    &[data-control-status='#{$status}'] {
      --assets-main-status-color: var(#{$color});
    }
  }
}
