@use '../../public/assets.mixins' as public-mixins;

@mixin override-styles {
  tui-hint {
    color: var(--assets-base-01) !important;
    background: var(--assets-text-01) !important;
    border-radius: var(--assets-radius-s) !important;

    &[data-appearance='light'] {
      color: rgb(18 18 18) !important;
      background: rgb(255 255 255 / 96%) !important;
    }
  }
}
