@use 'variables' as *;
@use 'mixins' as *;

@mixin text-view($text-align: center, $color: rgb(var(--twilight))) {
  $position: relative;
  text-align: $text-align;
  color: $color;
  position: $position;
  z-index: 10;
}

@mixin title-gradient($gradient1: $gradient-silver-2, $gradient2: $gradient-default) {
  &--default {
    background-image: $gradient1;
  }
  &--colored {
    background-image: $gradient2;
  }
}

.global-hero-text {
  @include text-view;
  @include font(16px, 24px);

  @include media('min', 'lg') {
    @include text-view(left);
    max-width: 523px;
  }
}


button.hero-scroll-down {
  background: center 18% / 20% no-repeat url(../../../assets/images/shared/swipe-down-arrow.svg),
  rgba(var(--purple), 0.2);
  position: absolute;
  width: 80px;
  height: 160px;
  left: 50%;
  top: 90%;
  transform: translateX(-50%);
  border-radius: 122px;
  z-index: 3;
}
