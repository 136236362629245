@use './lib.mixins' as lib-mixins;

@include lib-mixins.apply-styles-to-library {
  :where(*) {
    font-family: var(--assets-font-family);
  }
}

// *scroll bar
::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

::-webkit-scrollbar-track {
  background-color: var(--assets-base-01);
  border-radius: 6px;
}

::-webkit-scrollbar-thumb {
  background-color: var(--assets-base-06);
  border-radius: 6px;
}

// *scroll bar
