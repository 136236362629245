@use 'sass:map' as map;

$link-sizes: (
  l: (
    size: 18px,
  ),
  m: (
    size: 16px,
  ),
  s: (
    size: 14px,
  ),
  xs: (
    size: 12px,
  ),
);

@mixin override-styles {
  [tuiLink] {
    --tui-text-action: var(--assets-link);

    text-decoration-thickness: 1px;
    text-decoration-color: currentColor;

    @each $size, $values in $link-sizes {
      &[data-size='#{$size}'] {
        --tui-link: var(--assets-link);

        font-size: map.get($values, 'size');
      }
    }

    &:hover {
      --tui-text-action-hover: var(--assets-link-hover);
    }

    &:active {
      --tui-text-action-hover: var(--assets-primary-active);
    }

    &[data-appearance='grey'] {
      --tui-text-action: var(--assets-text-02);

      opacity: 64%;

      &:hover,
      &:active {
        opacity: unset;
      }

      &:hover {
        color: var(--assets-link-hover);
      }

      &:active {
        color: var(--assets-primary-active);
      }
    }
  }
}
