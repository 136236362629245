//использовал important, потому что со страпи приходят инлайн стили с шириной для картинок

.single-page {
  .text-default {
    @include font(16px, 28px);
    margin-bottom: 16px;
    color: rgb(var(--neutral-1000));
  }

  .list {
    padding-left: 16px;

    li {
      @extend .text-default;
    }
  }

  h2, h3:not(.heading-3-v2), h4 {
    color: rgb(var(--neutral-900));
    margin-bottom: 8px;
    margin-top: 32px;
  }

  h2 {
    font-weight: bold;
    @include font(32px, 44.8px);
  }

  h3:not(.heading-3-v2), {
    font-weight: bold;
    @include font(28px, 39.2px);
  }

  h4 {
    font-weight: bold;
    @include font(24px, 33.6px);
  }

  p {
    @extend .text-default;
  }

  i {
    font-style: italic;
  }

  strong {
    font-weight: bold;
  }

  a {
    color: rgb(var(--purple));
    position: relative;

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      height: 1px;
      width: 100%;
      background: rgb(var(--purple));
    }
  }

  ol, ul {
    @extend .list;
  }

  ul {
    li {
      list-style-type: disc;
    }
  }

  ol {
    li {
      list-style-type: decimal;
    }
  }

  .table {
    float: initial !important;
    width: 100% !important;
    overflow-x: auto;
    margin-bottom: 16px;
  }

  table {
    border-collapse: collapse;
    width: 100%;
    margin-bottom: 8px;
    border-radius: 16px;
    min-width: 680px;

    tr {
      @include font(16px, 19px);
      color: #000;
      &:first-child {
        border-radius: 16px 16px 0 0;

        td {
          background-color: rgb(var(--neutral-100)) !important;
        }
      }

      &:last-child {
        border-bottom: 1px solid rgb(var(--neutral-200));
      }
    }

    td, th {
      padding: 10px 12px !important;
      border: 1px solid rgb(var(--neutral-200)) !important;
    }

    th {
      background-color: rgb(var(--neutral-100));
    }

    tr {
      td:first-child {
        background-color: rgb(var(--neutral-100))!important;
      }
    }
  }

  &__article {
    img {
      width: 100% !important;
      margin-top: 16px;
      margin-bottom: 8px;
    }
  }

  .articles {
    li:last-child,
    p:last-child {
      margin: 0;
    }
  }
}
