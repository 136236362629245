@use 'index' as index;
@use '../node_modules/@noda-lib/assets/styles/index.scss' as assets-lib;
@use '../node_modules/@noda-lib/assets/styles/public/assets.variables' as assets-public-vars;

@import '../node_modules/scss-reset/_reset.scss';

@include assets-public-vars.override-app-variables(
    (
      btn-padding-inline-l: 48px,
      btn-padding-inline-m: 32px,
      btn-padding-inline-s: 24px,
      btn-padding-inline-xs: 12px,
      btn-radius-l: 6px,
      btn-radius-m: 6px,
      btn-radius-s: 4px,
      btn-radius-xs: 2px,
    ),
    'app'
);

// override buttons height
@include assets-public-vars.override-app-variables(
    (
      height-l: 56px,
      height-m: 48px,
      height-s: 38px,
      height-xs: 24px,
    ),
    'taiga'
);

@include assets-lib.init-font('Inter');

::-webkit-scrollbar {
  width: 0;
}

.dark-mode,
.dark-header {
  /* Common styles for header */
  --header-background: rgb(var(--neutral-1000));
  --header-text-color: rgb(var(--white));
  --hover-item: rgb(var(--purple-01));
  --header-sub-text-color: var(--text-03);
  --subtitle-color: rgb(var(--white));
  --subtitle-border: rgb(var(--base-07));
  --header-box-shadow: 0px 100px 120px 0px rgba(0, 0, 0, 0.85);

  /* Styles for elements inside the header */
  /* Lang switch */
  --lang-bg-hover: rgb(var(--base-07));

  /* Icons */
  --icon-first-color: rgba(var(--white));
  --icon-second-color: rgb(var(--neutral-1000));
  --icon-border: rgb(var(--base-07));

  /* Logo */
  --logo-color: rgb(var(--white));
  --logo-third-color: rgb(var(--white));
  --logo-fourth-color: rgb(var(--white));
  --logo-sub-color: rgb(var(--white));

  /* Mobile */
  --arrow-acc-color: rgb(var(--base-03));
  --subtitle-mobile-border: rgb(var(--base-08));
}

.white-mode {
  /* Common styles for header */
  --header-background: rgb(var(--white));
  --header-text-color: var(--text-01);
  --hover-item: rgb(var(--purple));
  --header-sub-text-color: var(--text-02);
  --subtitle-color: var(--text-02);
  --subtitle-border: rgb(var(--neutral-300));
  --header-box-shadow: 0px 100px 120px 0px rgba(14, 0, 101, 0.15);

  /* Styles for elements inside the header */
  /* Mobile */
  --arrow-acc-color: rgb(var(--base-07));
  --subtitle-mobile-border: rgb(var(--base-04));

  /* Lang switch */
  --lang-bg-hover: rgb(var(--base-03));

  /* Icons */
  --icon-first-color: rgb(var(--neutral-1000));
  --icon-second-color: rgba(var(--white));
  --icon-border: rgb(var(--base-06));

  /* Logo */
  --logo-color: rgb(var(--cobalt-blue));
  --logo-third-color: rgb(var(--cobalt-blue));
  --logo-fourth-color: rgb(var(--purple));
  --logo-sub-color: rgb(var(--safety-orange));
}

html {
  //! DON'T use overflow in top level elements as default
  // overflow-x: hidden;
  &.no-body-scroll {
    overflow: hidden;
    height: 100%;

    body {
      overflow-y: scroll;
    }
  }
}

body {
  &.stop-scroll {
    overflow: hidden;
    height: 100%;
  }

  width: 100%;
  min-width: 320px;
}

//reset
* {
  -webkit-tap-highlight-color: transparent;

  &:hover,
  &:focus,
  &:active {
    outline: none;
  }
}

// For all SVG-Icons
svg-icon {
  display: inline-flex;
}

a {
  text-decoration: none;
}

.iframe-hidden {
  display: none;
  visibility: hidden;
}

b {
  font-weight: 700;
}
