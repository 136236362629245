@use 'sass:map';

$status-color-map: (
  error: --assets-error-fill,
  warning: --assets-accent,
  success: --assets-success-fill,
  primary: --assets-primary,
);

$fonts-map: (
  // * Headers text
  font-heading-1: 800 50px/55px var(--assets-font-family),
  font-heading-2: 800 44px/48.4px var(--assets-font-family),
  font-heading-3: 800 32px/35.2px var(--assets-font-family),
  font-heading-4: 900 24px/26.4px var(--assets-font-family),
  font-heading-5: 800 24px/28.8px var(--assets-font-family),
  font-heading-6: 700 20px/24px var(--assets-font-family),
  // * Body text
  font-text-xl-bold: 700 18px/27px var(--assets-font-family),
  font-text-xl: 400 18px/27px var(--assets-font-family),
  font-text-l-bold: 700 16px/22.4px var(--assets-font-family),
  font-text-l-medium: 500 16px/22.4px var(--assets-font-family),
  font-text-l: 400 16px/22.4px var(--assets-font-family),
  font-text-m-bold: 700 14px/18.2px var(--assets-font-family),
  font-text-m: 400 14px/18.2px var(--assets-font-family),
  font-text-s-bold: 600 12px/15.6px var(--assets-font-family),
  font-text-s: 400 12px/15.6px var(--assets-font-family)
);

.assets-text {
  &-h1 {
    font: var(--assets-font-heading-1);
  }

  &-h2 {
    font: var(--assets-font-heading-2);
  }

  &-h3 {
    font: var(--assets-font-heading-3);
  }

  &-h4 {
    font: var(--assets-font-heading-4);
    letter-spacing: -0.8px;
  }

  &-h5 {
    font: var(--assets-font-heading-5);
  }

  &-h6 {
    font: var(--assets-font-heading-6);
  }

  &-body {
    $short-line: 13.2px;

    &-xl-bold {
      font: var(--assets-font-text-xl-bold);
    }

    &-xl {
      font: var(--assets-font-text-xl);
    }

    &-l-bold {
      font: var(--assets-font-text-l-bold);
    }

    &-l-medium {
      font: var(--assets-font-text-l-medium);
    }

    &-l {
      font: var(--assets-font-text-l);
    }

    &-m-bold {
      font: var(--assets-font-text-m-bold);
    }

    &-m {
      font: var(--assets-font-text-m);
    }

    &-s-bold {
      font: var(--assets-font-text-s-bold);
    }

    &-s {
      font: var(--assets-font-text-s);
    }

    &-s-bold-short-line {
      font: var(--assets-font-text-s-bold);
      line-height: $short-line;
    }

    &-s-short-line {
      font: var(--assets-font-text-s);
      line-height: $short-line;
    }

    &.disabled {
      color: var(--assets-text-03);
    }
  }

  &-link {
    cursor: pointer;
    color: var(--assets-link);
    text-decoration: underline;

    &:hover:not(.assets-text-disabled) {
      color: var(--assets-link-hover);
    }
  }

  &-primary {
    color: var(--assets-primary);
  }

  &-label {
    font: var(--assets-font-text-l);
    color: var(--assets-text-01);
    transition: color var(--assets-animation);

    &.disabled {
      color: var(--assets-text-02);
    }
  }

  &-disabled {
    cursor: default;
    color: var(--assets-text-03);
  }

  @each $status, $status-color in $status-color-map {
    &-#{$status} {
      color: var(#{$status-color});
    }
  }
}
