@use 'sass:string';
@use './lib.fonts' as fonts;
@use './lib.constants' as lib-const;
@use './lib.mixins' as lib-mixins;
@use '../taiga/taiga.constants' as taiga-const;

$font-host: lib-const.$host !default;

@function create-font-list($font-name) {
  @return (
    '#{$font-name}-Thin': 100,
    '#{$font-name}-ExtraLight': 200,
    '#{$font-name}-Light': 300,
    '#{$font-name}-Regular': 400,
    '#{$font-name}-Medium': 500,
    '#{$font-name}-SemiBold': 600,
    '#{$font-name}-Bold': 700,
    '#{$font-name}-ExtraBold': 800,
    '#{$font-name}-Black': 900
  );
}

@function create-folder-path($host, $font-name) {
  $fonts-root-folder: '#{$host}/storage/fonts/woff';

  @return '#{$fonts-root-folder}/#{$font-name}';
}

@mixin download-fonts($font-name, $fonts, $font-folder-path) {
  @each $font-family, $fw in $fonts {
    @font-face {
      font-family: $font-name;
      font-weight: $fw;
      src: url('#{$font-folder-path}/#{$font-family}.woff');
    }
  }
}

@mixin init-font(
  $font-name,
  $host: $font-host,
  $reserve-font-name: 'Halvetica',
  $download-reserve-font: false
) {
  @if $font-name == 'Archivo' {
    $reserve-font-name: 'Inter';
  }

  :root {
    --assets-font-family: #{$font-name}, #{$reserve-font-name}, Arial, sans-serif;
    --assets-font-family-#{string.to-lower-case($font-name)}: #{$font-name}, #{$reserve-font-name},
      Arial, sans-serif;

    @include lib-mixins.apply-variables(fonts.$fonts-map, lib-const.$global-prefix);
    @include lib-mixins.apply-variables(fonts.$fonts-map, taiga-const.$taiga-prefix);
  }

  @include download-fonts(
    $font-name,
    create-font-list($font-name),
    create-folder-path($host, $font-name)
  );

  @if $download-reserve-font == true {
    @include download-fonts(
      $reserve-font-name,
      create-font-list($reserve-font-name),
      create-folder-path($host, $reserve-font-name)
    );
  }
}

@include init-font('Archivo', $download-reserve-font: true);
