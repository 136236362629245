@mixin override-styles {
  tui-badge {
    &[data-size='xl'] {
      border-radius: 6px;
    }

    &[data-size='l'],
    &[data-size='m'],
    &[data-size='s'] {
      border-radius: 4px;
    }

    &[data-size='l'] {
      font: var(--assets-font-text-m);
    }

    &[data-size='xl'],
    &[data-size='l'] {
      line-height: 1;
    }

    &[data-size='xl'],
    &[data-size='l'],
    &[data-size='m'] {
      gap: 4px;
    }

    &[data-size='s'] {
      gap: 2px;
    }
  }
}
