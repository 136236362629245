@use './lib.constants' as lib-const;

@mixin apply-styles-to-library {
  .#{lib-const.$global-prefix}-content {
    @content;
  }
}

@mixin apply-variables($vars-map, $prefix: lib-const.$global-prefix) {
  @each $key, $value in $vars-map {
    --#{$prefix}-#{$key}: #{$value};
  }
}
