@use './theme.constants' as theme-const;
@use '../taiga/taiga.constants' as taiga-const;
@use '../lib/lib.mixins' as lib-mixins;

@mixin apply-theme($light-pallette, $dark-pallette) {
  @if $light-pallette {
    :root,
    :root[#{theme-const.$theme-mode-selector}='light'] {
      @include lib-mixins.apply-variables($light-pallette);
      @include lib-mixins.apply-variables($light-pallette, taiga-const.$taiga-prefix);
    }
  }

  @if $dark-pallette {
    :root[#{theme-const.$theme-mode-selector}='dark'] {
      @include lib-mixins.apply-variables($dark-pallette);
      @include lib-mixins.apply-variables($dark-pallette, taiga-const.$taiga-prefix);
    }
  }
}

@mixin override-theme($light-pallette: null, $dark-pallette: null) {
  @include apply-theme($light-pallette, $dark-pallette);
}
