/* stylelint-disable selector-class-pattern */
@use 'sass:map' as map;
@use '@taiga-ui/core/styles/taiga-ui-local.scss' as taiga-ui;

$statuses-colors-map: (
  default: (
    blur: --assets-base-03,
    blur-border: --assets-base-05,
    blur-label: --assets-text-03,
    blur-icon: --assets-text-03,
    focus: --assets-info-fill,
    focus-icon: --assets-info-fill,
    focus-label: --assets-info-fill,
    focus-border: --assets-info-fill,
    hover-border: --assets-secondary-active,
  ),
  error: (
    blur: --assets-error-fill,
    blur-border: --assets-error-fill,
    blur-label: --assets-error-fill,
    blur-icon: --assets-error-fill,
    focus: --assets-error-fill,
    focus-icon: --assets-error-fill,
    focus-label: --assets-error-fill,
    focus-border: --assets-error-fill,
  ),
  warning: (
    blur: --assets-accent,
    blur-border: --assets-accent,
    blur-label: --assets-accent,
    focus: --assets-accent,
    focus-icon: --assets-accent,
    focus-label: --assets-accent,
    focus-border: --assets-accent,
  ),
  success: (
    blur: --assets-success-fill,
    blur-border: --assets-success-fill,
    blur-label: --assets-success-fill,
    focus: --assets-success-fill,
    focus-icon: --assets-success-fill,
    focus-label: --assets-success-fill,
    focus-border: --assets-success-fill,
  ),
  disabled: (
    blur: --assets-base-04,
    blur-border: --assets-base-04,
    blur-input: --assets-text-02,
    blur-label: --assets-text-03,
    blur-icon: --assets-text-03,
    blur-background: --assets-base-02,
  ),
);
$default-modifiers: (
  normal: (
    blur-border: --assets-base,
  ),
  stroke: (
    blur-border: --assets-base-05,
  ),
);

@each $status, $colors-map in $statuses-colors-map {
  [data-control-status='#{$status}'] {
    --assets-textfield-input-color: var(#{map.get($colors-map, 'blur-input')});
    --assets-textfield-label-color: var(#{map.get($colors-map, 'blur-label')});
    --assets-textfield-icon-color: var(#{map.get($colors-map, 'blur-icon')});
    --assets-textfield-background-color: var(#{map.get($colors-map, 'blur-background')});
    --assets-textfield-border-color: var(#{map.get($colors-map, 'blur-border')});

    @include taiga-ui.wrapper-hover {
      &:not(:focus-within) {
        --assets-textfield-border-color: var(#{map.get($colors-map, 'hover-border')});
      }
    }

    [tuiWrapper][data-appearance='textfield'] {
      // ? taiga-ui.wrapper-focus работает с косяками реализации в тайге
      &:focus-within {
        --assets-textfield-border-color: var(#{map.get($colors-map, 'focus-border')});
        --assets-textfield-icon-color: var(#{map.get($colors-map, 'focus-icon')});
        --assets-textfield-label-color: var(#{map.get($colors-map, 'focus-label')});
      }
    }

    //Default status input has two states : normal or stroke
    @if $status == 'default' {
      @each $modifier, $modifier-map in $default-modifiers {
        &[data-control-modifier='#{$modifier}'] {
          --assets-textfield-border-color: var(#{map.get($modifier-map, 'blur-border')});
        }
      }
    }
  }
}

@mixin override-styles {
  .tui-autofill {
    transition: border-radius var(--assets-animation);
  }

  [tuiWrapper][data-appearance='textfield'] {
    --tui-disabled-opacity: 1;

    font: var(--assets-font-text-l-medium);

    background-color: var(--assets-textfield-background-color, var(--assets-base-01));
    border-radius: var(--assets-radius-s);
    box-shadow: none;

    transition: background-color var(--assets-animation);

    &::after {
      border-color: var(--assets-textfield-border-color, var(--assets-base-05));
      transition:
        border-color 0.1s ease-in,
        border-width 0.1s ease-in;
    }

    input,
    textarea {
      color: var(--assets-textfield-input-color, var(--assets-text-01));
    }

    .t-placeholder {
      font: var(--assets-font-text-l) !important;
      color: var(--assets-textfield-label-color, var(--assets-text-03)) !important;
    }

    .t-placeholder_raised {
      font: var(--assets-font-text-s) !important;
      color: var(--assets-textfield-label-color, var(--assets-text-03)) !important;
    }

    label {
      font: var(--assets-font-text-l) !important;
      color: var(--assets-textfield-label-color, var(--assets-text-03)) !important;

      //  ? Класс для НЕ заполненного инпута
      // &:not(.t-placeholder_raised) {
      //   font: var(--assets-font-text-s) !important;
      // }
    }

    @include taiga-ui.wrapper-hover {
      box-shadow: none;
    }

    .assets-control-status-icon {
      color: var(--assets-textfield-icon-color, var(--assets-text-03));
      transition: color var(--assets-animation);
    }
  }
}

/*
    Остальные состояния (https://taiga-ui.dev/wrapper)

    @include taiga-ui.wrapper-active {
    }
    @include taiga-ui.wrapper-disabled {
    }
    @include taiga-ui.wrapper-readonly {
    }
    @include taiga-ui.wrapper-invalid {
    }
*/
