@use "sass:string";
@use "variables" as vars;
@use "variables" as *;
@use '@taiga-ui/core/styles/taiga-ui-local.scss' as taiga-ui;
@forward '../../../node_modules/@noda-lib/assets/styles/public/assets.mixins.scss';

@function cdn-url($path) {
  @return url("#{vars.$store-host}/#{$path}");
}

// Flex
@mixin flex($align-items: center, $justify-content: center, $flex-direction: row) {
  display: flex;
  align-items: $align-items;
  justify-content: $justify-content;
  flex-direction: $flex-direction;
}

//

// Placeholder
@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
}

// Hover
@mixin hover {
  @media(hover: hover) {
    &:hover {
      @content;
    }
  }
}

@mixin host-hidden() {
  :host {
    display: block;
    overflow: hidden;
  }
}

@mixin transition($property: all, $time: 0.25s) {
  transition: $property $time linear;
}

// Font
@mixin font($font-size, $line-height) {
  font-size: $font-size;
  line-height: $line-height;
}

// Media queries:
$sizes: ("xs": 376px, "sm": 576px, "md": 768px, "lg": 1024px, "xl": 1200px, "xxl": 1440px, "full": 1920px, "qhd": 2560px);
@function getPreviousSize($currentSize) {
  $keys: map-keys($sizes);
  $index: index($keys, $currentSize)-1;
  $value: map-values($sizes);
  @return nth($value, $index);
}

@mixin media($minmax, $media) {
  @each $size, $resolution in $sizes {
    @if $media == $size {
      @if ($minmax != "within") {
        @media only screen and (#{$minmax}-width: $resolution) {
          @content;
        }
      } @else {
        @if (index(map-keys($sizes), $media) > 1) {
          @media only screen and (min-width: getPreviousSize($media)) and (max-width: $resolution) {
            @content;
          }
        } @else {
          @media only screen and (max-width: $resolution) {
            @content;
          }
        }
      }
    }
  }
}

@mixin bg-card($border: $border-8, $shadow: $card-shadow, $bg: none) {
  border-radius: $border;
  backdrop-filter: blur(10px);
  box-shadow: $shadow;
  background: $bg;
}

@mixin overrideTuiButtonStyles(
  $background,
  $backgroundHover,
  $backgroundActive,
  $backgroundDisabled,
  $color,
  $colorHover,
  $colorActive,
  $colorDisabled,
) {
  background: $background;
  color: $color;

  @include taiga-ui.wrapper-hover {
    background-color: $backgroundHover !important;
    color: $colorHover;
  }

  @include taiga-ui.wrapper-active {
    background-color: $backgroundActive !important;
    color: $colorActive;
  }

  @include taiga-ui.wrapper-disabled {
    background-color: $backgroundDisabled !important;
    color: $colorDisabled;
  }
}

@mixin set-grid-area-name($elRef, $itemsNumber, $areaName: 'item') {
  @for $i from 1 through $itemsNumber {
    #{$elRef}:nth-child(#{$i}) {
      grid-area: #{$areaName} + #{$i};
    }
  }
}
