:root {
  .mat-mdc-form-field {
    width: 100%;
    // START DARK THEME
    &.dark {
      .mdc-text-field {
        background: rgb(var(--dark-blue));
        border: 1px solid rgba(var(--lucky-point), 0.2);

        &:hover {
          border: 1px solid rgb(var(--light-slate-blue2));
        }

        .mdc-floating-label {
          color: rgb(var(--white));
          opacity: 0.6;
        }

        &--focused {
          border: 1px solid rgb(var(--light-slate-blue2));

          .mdc-floating-label {
            &--float-above {
              color: rgb(var(--light-slate-blue2));
            }
          }
        }

        input {
          color: rgb(var(--neutral-200));
        }
      }
    }
    // END DARK THEME

    &-subscript-wrapper {
      display: none;
    }

    .mat-mdc-text-field-wrapper {
      padding: 0 20px;
    }

    .mat-mdc-floating-label {
      font-weight: 400;
      color: var(--text-03);
    }

    .mdc-line-ripple {
      &:before {
        display: none;
      }

      &:after {
        display: none;
      }
    }

    .mat-mdc-form-field {
      .mat-mdc-floating-label.mdc-floating-label {
        @include font(16px, 29px);
      }
    }

    .mdc-text-field {
      min-height: 50px;
      border-radius: 4px;
      display: flex;
      align-items: center;
      background: rgb(var(--white));
      border: 1px solid rgb(var(--neutral-300));
      padding-left: 17px;
      padding-right: var(--padding-right, 17px);
      @include transition();

      &:hover {
        border: 1px solid rgb(var(--purple));
      }

      &--filled {
        .mdc-floating-label--float-above {
          @include font(14px, 29px);
          transform: translateY(-25px) scale(0.84);
        }
      }

      .mdc-floating-label {
        top: 23px;
        color: rgb(var(--lucky-point));
      }

      &--focused {
        box-shadow: $input-focus;
        border: 1px solid rgb(var(--purple));

        .mdc-floating-label {
          &--float-above {
            color: rgb(var(--purple));
          }
        }
      }

      input {
        color: rgb(var(--neutral-1000));
        height: 29px;
        @include font(16px, 112%);
        margin-top: 18px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
}
