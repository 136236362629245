/**
 * Миксин для мобильных устройств.
 *
 * @param {number} $max-mobile-width - Максимальная ширина экрана для мобильных устройств. По умолчанию 767px.
 * @example
 * .example {
 *   @include mobile() {
 *     background-color: red;
 *   }
 * }
 */
@mixin mobile($max-mobile-width: 767px) {
  @media (max-width: $max-mobile-width) {
    @content;
  }
}

/**
 * Миксин для настольных устройств.
 *
 * @param {number} $min-desktop-width - Минимальная ширина экрана для настольных устройств. По умолчанию 768px.
 * @example
 * .example {
 *   @include desktop() {
 *     background-color: blue;
 *   }
 * }
 */
@mixin desktop($min-desktop-width: 768px) {
  @media (min-width: $min-desktop-width) {
    @content;
  }
}

/**
 * Миксин для устройств с сенсорным экраном.
 *
 * @example
 * .example {
 *   @include for-touch-screen() {
 *     background-color: green;
 *   }
 * }
 */
@mixin for-touch-screen() {
  @media (pointer: coarse) {
    @content;
  }
}

/**
 * Миксин для устройств с мышью.
 *
 * @example
 * .example {
 *   @include for-mouse-screen() {
 *     background-color: yellow;
 *   }
 * }
 */
@mixin for-mouse-screen() {
  @media (pointer: fine) {
    @content;
  }
}

/**
 * Миксин для устройств, поддерживающих hover.
 *
 * @example
 * .example {
 *   @include for-hover-screen() {
 *     background-color: purple;
 *   }
 * }
 */
@mixin for-hover-screen() {
  @media (hover: hover) {
    @content;
  }
}

/**
 * Миксин для устройств, не поддерживающих hover.
 *
 * @example
 * .example {
 *   @include for-none-hover-screen() {
 *     background-color: orange;
 *   }
 * }
 */
@mixin for-none-hover-screen() {
  @media (hover: none) {
    @content;
  }
}
