/* stylelint-disable selector-class-pattern */

@mixin override-styles {
  [tuiCheckbox]:not([role='switch']) {
    --tui-disabled-opacity: 1;
    --assets-checkbox-size: 16px;

    width: var(--assets-checkbox-size) !important;
    height: var(--assets-checkbox-size) !important;
    border-radius: var(--assets-radius-s) !important;
    transition: background-color var(--assets-animation);

    &[data-size='s'] {
      --assets-checkbox-size: 16px;
    }

    &[data-size='m'],
    &[data-size='l'] {
      --assets-checkbox-size: 24px;
    }

    &[data-appearance='primary'] {
      background-color: var(--assets-primary);

      &:hover {
        background-color: var(--assets-primary-hover);
      }

      &:focus {
        background-color: var(--assets-primary-active);
      }
    }

    &[data-appearance='whiteblock'] {
      background-color: var(--assets-base-02);
      border: 1px solid var(--assets-base-06);

      &:hover {
        background-color: var(--assets-base-03);
      }

      &:focus {
        background-color: var(--assets-base-04);
      }
    }

    &:disabled {
      opacity: var(--tui-control-disabled-opacity) !important;
    }
  }
}
