@use "variables" as *;
@use "mixins" as *;

.payments {
  .swiper-slide {
    display: flex;
    width: 293px !important;
    margin-right: 8px !important;
  }

  .swiper-button-disabled {
    display: none !important;
  }
}

.geo__swiper {
  .swiper-slide {
    @include flex(center, center);
    @include font(15.6481px, 27px);

    gap: 4px;

    width: 76px !important;
    margin-right: 12px !important;
    padding: 4px 18px;

    font-weight: 500;
    color: rgb(var(--magnolia-white));
    text-transform: uppercase;

    background: rgba(var(--black-1), 0.8);
    backdrop-filter: blur(6.7063px);
    border: 1px solid rgb(var(--neutral-1000));
    border-radius: $border-38;
  }
}

.currency__swiper {
  .swiper-slide {
    @include media("min", "lg") {
      width: 108px !important;
      margin-right: 23px !important;
    }

    width: 60px !important;
    margin-right: 13px !important;

    svg-icon {
      @include media("min", "lg") {
        --icon-width: 108px;
        --icon-height: 108px;
      }
    }
  }
}

.reviews-swiper {
  .swiper-slide {

    @include media("min", "sm") {
      width: 467px !important;
    }

    width: 306px !important;
  }
}

.reviews-v2-swiper {
  .swiper-wrapper {
    align-items: stretch;

    &:last-child {
      margin-right: 16px;
    }
  }

  .swiper-slide {

    @include media("min", "md") {
      width: 462px !important;
    }

    width: 332px !important;
    height: auto !important;
  }
}

.hub__swiper {
  .swiper-slide {
    @include bg-card($border-8, $img-swiper-shadow, rgba(var(--white), 0.8));
    @include flex(flex-start, flex-start, column);

    gap: 7px;
    width: 246px !important;
  }
}

.how-swiper {
  .swiper-slide {
    @include media("min", "md") {
      width: 100% !important;
      margin-right: 0;
    }
    @include media("min", "xxl") {
      position: relative;
    }

    width: 297px !important;
    margin-right: 20px;
  }

  .swiper-slide-active {
    .pagination {
      &::after {
        @include transition;

        background: $gradient-default !important;
      }
    }

    .pagination__text {
      @include transition;

      color: transparent !important;
      background-image: $gradient-default;
      background-clip: text;
      box-decoration-break: clone;
    }
  }
}

.how {
  &.reusable {
    .how-swiper {
      .swiper-slide:nth-child(2) {
        @include media("min", "lg") {
          .how-swiper__img {
            width: 438px;
          }
        }
        @include media("min", "xxl") {
          .how-swiper__img {
            position: absolute;
            right: -215px;
            transform: translateY(8px);

            min-width: 1080px;
            height: 1080px;
            margin: 0 auto;
          }
        }

        .how-swiper__img {
          box-shadow: none !important;
        }
      }
    }
  }
}

.banks__swiper {
  .swiper-slide {
    @include media("min", "lg") {
      width: 56px;
      height: 56px;
      margin-right: 32px;
    }

    width: 40px;
    height: 40px;
    margin-right: 24px;
  }

  .swiper-wrapper {
    transition-timing-function: linear !important;
  }
}

.main-blocks {
  &__swiper {
    .swiper-slide {
      width: 324px !important;
    }
  }
}

.data-usage {
  &__swiper {
    .swiper-slide {
      width: 70px !important;
    }
  }
}

.hood {
  &__swiper {
    .swiper-slide {
      @include font(15px, 20px);
      @include media("min", "lg") {
        @include font(16px, 28px);
      }

      width: max-content;
      padding: 10px 16px;

      color: rgb(var(--neutral-1000));

      background: rgb(var(--alice-blue-2));
      border-radius: $border-40;

      &-active {
        color: rgb(var(--white));
        background: $gradient-default;
      }
    }
  }

  &__partners {
    .swiper-slide {
      width: max-content;
    }
  }
}

.leadership-slider {
  .swiper-wrapper {
    align-items: center;
  }

  .swiper-slide {

    @include media("min", "lg") {
      width: 64px;
      margin: 0 -8px;

      &:first-of-type {
        margin-left: 0;
      }
    }

    @include media("min", "xxl") {
      margin: 0 -11px;

      &:first-of-type {
        margin-left: 0;
      }
    }

    width: 100px;

    &-active {

      @include media("min", "md") {
        width: 210px;
      }

      @include media("min", "lg") {
        width: 64px;
      }

      width: 180px;
    }

    &:has(.active) {
      @include media("min", "lg") {
        margin: 0 12px 0 0;

        &:last-of-type {
          margin-left: -14px;
        }
      }
    }

    &-prev, &-next {

      @include media("min", "lg") {
        max-width: 64px;
      }

      max-width: 100px;
    }

    @for $i from 1 through 10 {
      &:nth-child(#{$i}) {
        z-index: 10 - $i;
      }
    }
  }
}

.manager__swiper {
  .swiper-wrapper {
    align-items: center;
  }

  .swiper-slide {
    @include media("min", "md") {
      width: 90px !important;
      margin: 0 12px !important;
    }
    @include media("min", "lg") {
      width: 98px !important;
    }

    cursor: pointer;
    width: 47px !important;
    margin: 0 7px !important;

    &:last-child {
      margin: 0 !important;
    }

    &-active {
      @include flex(center, center);
      @include media("min", "md") {
        width: 120px !important;
        height: 120px !important;
        margin: 0 -3px !important;
      }
      @include media("min", "lg") {
        width: 134px !important;
        height: 134px !important;
        margin: 0 -6px !important;
      }

      width: 63px !important;
      height: 63px !important;
      margin: 0 -1px !important;

      background: $gradient-default;
      border-radius: 50%;

      .slide__wrapper {
        @include flex(center, center);
        @include media("min", "md") {
          width: 110px;
          height: 110px;
          padding: 10px;
        }
        @include media("min", "lg") {
          width: 125px;
          height: 125px;
        }

        padding: 3px;
        background: rgb(var(--neutral-900));
        border-radius: 50%;
      }

      img {
        @include media("min", "md") {
          width: 103px !important;
          min-width: 103px;
          height: 103px !important;
        }
        @include media("min", "lg") {
          width: 116px !important;
          min-width: 116px;
          height: 116px !important;
        }

        width: 50px !important;
        height: 50px !important;
      }
    }

    img {
      @include media("min", "md") {
        width: 90px;
        height: 90px;
      }
      @include media("min", "lg") {
        width: 98px;
        height: 98px;
      }

      width: 47px;
      height: 47px;
    }
  }
}

.dashboard {
  &__info {
    .swiper-slide {
      width: 100% !important;
    }
  }
}

.custom-pagination {
  .swiper-pagination-bullet {
    width: 6px;
    height: 6px;
    margin: 0;
    opacity: 1;
  }

  .swiper-pagination-bullet-active {
    width: 12px;
    height: 12px;
  }
}

.reviews__pagination {
  .swiper-pagination-bullet {
    background: rgb(var(--neutral-600));
  }

  .swiper-pagination-bullet-active {
    background: rgb(var(--white));
  }
}

.main-noda__pagination.swiper-pagination-horizontal.swiper-pagination-bullets  {
  .swiper-pagination-bullet {
    margin: 0;
  }
}

.reviews-v2-pagination.swiper-pagination-horizontal.swiper-pagination-bullets {
  .swiper-pagination-bullet {
    position: relative;

    width: 12px;
    height: 12px;
    margin: 0;

    background-color: transparent;

    &::before {
      content: '';

      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      width: 6px;
      height: 6px;

      background: rgb(var(--light-slate-blue2));
      border-radius: 50%;

    }
  }

  .swiper-pagination-bullet-active {
    margin: 0;
    background: rgb(var(--purple));

    &::before {
      display: none;
    }
  }
}

.swipe-pagination {
  .swiper-pagination-bullet {
    background: rgb(var(--lucky-point));
  }

  .swiper-pagination-bullet-active {
    background: rgb(var(--mulberry-2));
  }
}

.swipe-pagination-noda {
  .swiper-pagination-bullet {
    background: rgb(var(--neutral-600));
  }

  .swiper-pagination-bullet-active {
    background: rgb(var(--white));
  }
}

.articles-hero__swiper, .contact-swiper {
  .swiper-slide {
    width: max-content;
  }
}

.articles-blocks__swiper {
  .swiper-slide {
    @include media("min", "md") {
      grid-gap: 30px;
      grid-template-columns: 1fr 1fr;
    }

    display: grid;
    grid-row-gap: 24px;
    grid-template-columns: 100%;
  }
}

.articles-blocks__pagination {
  @include flex;

  gap: 23px;
  margin-top: 48px;

  .swiper-pagination-bullet {
    @include font(20px, 32px);

    width: max-content;
    height: initial;
    margin: 0 !important;

    font-weight: 500;
    color: rgb(var(--black));
    letter-spacing: -1px;

    opacity: 1;
    background: none;
    border-radius: 0;

    &-active {
      @extend .gradient-bg;

      background: $gradient-default;
    }
  }
}

.available {
  .swiper-slide {
    width: max-content;
    margin-right: 20px;
  }
}

.product {
  .swiper-slide {
    width: auto;
  }
}