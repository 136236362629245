/* stylelint-disable selector-class-pattern */

@mixin override-styles {
  [tuiRadio] {
    --tui-disabled-opacity: 1;

    transition: background-color var(--assets-animation);

    &[data-appearance='primary'] {
      background-color: var(--assets-primary);

      &:hover {
        background-color: var(--assets-primary-hover) !important;
      }

      &:focus {
        background-color: var(--assets-primary-active) !important;
      }
    }

    &[data-appearance='whiteblock'] {
      background-color: var(--assets-base-02);
      border: 1px solid var(--assets-base-06);

      &:hover {
        background-color: var(--assets-base-03) !important;
      }

      &:focus {
        background-color: var(--assets-base-04) !important;
      }
    }

    &:disabled {
      opacity: var(--tui-control-disabled-opacity) !important;
    }
  }
}
