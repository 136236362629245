.event-people-swipe {
  .swiper-slide {
    width: 60px;
    height: 69px;
    margin-right: 8px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.event-badge-slide {
  .swiper-slide {
    width: auto;
    margin-right: 8px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.events-all-photos {
  .swiper-slide {
    width: 100%;

    &:last-child {
      margin-right: 0;
    }
  }
}

.events-all-photos-mini {
  margin-top: 8px;

  .swiper-slide {
    width: auto;
    margin-right: 8px;
    border: 2px solid transparent;

    &.swiper-slide-thumb-active {
      border: 2px solid transparent;
      border-radius: 8px;
      background: linear-gradient(transparent 0 0) padding-box,
                  linear-gradient(89.94deg, #6936D3 0.05%, #F16F6F 99.95%) border-box;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}
.live-badge {
  height: 32px;
  @include flex();
  @include font(14px, 21.42px);
  letter-spacing: -0.28px;
  border-radius: 52px;
  padding-left: 12px;
  padding-right: 12px;
  background: linear-gradient(rgb(var(--neutral-100)) 0 0) padding-box,
  $gradient-default border-box;
  border: 1px solid transparent;

  .text {
    @extend .gradient-bg;
    background-image: $gradient-default;
    margin-left: 12px;
  }

  .pulse {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    position: relative;

    &__center {
      border-radius: 50%;
      background: $gradient-default;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
    }

    &:after,
    &:before {
      content: '';
      display: block;
      width: 14px;
      height: 14px;
      background: linear-gradient(rgb(var(--white)) 0 0) padding-box,
      $gradient-default border-box;
      border: 1px solid transparent;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      opacity: 0;
      border-radius: 50%;
      transform-origin: center center;
      animation: pulse 2.5s linear infinite;
    }

    &:after {
      animation-delay: 1.25s;
    }

    @keyframes pulse {
      0% {
        transform: translate(-50%, -50%) scale(0.4);
        opacity: 0;
      }
      70% {
        opacity: 1;
      }
      100% {
        transform: translate(-50%, -50%) scale(1.8);
        opacity: 0;
      }
    }
  }
}
